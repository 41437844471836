import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

import getPayments from "../../api/users/getPayments.js";
import depositUser from "../../api/users/depositUser.js";
import Breadcrumbs from "../../components/BreadcrumbsComponent";

import { toast } from 'react-toastify';

import PaginationComponent from "../../components/PaginationComponent/index.js";

import formatDate from "../../utils/formatDate.js";

export const WalletPage = () => {
    const { t } = useTranslation();

    const user = useSelector((state) => state.user);

    const [payments, setPayments] = useState([]);
    const [meta, setMeta] = useState({
        currentPage: 1,
        pageSize: 10,
        totalPages: 0,
        totalItems: 0
    });

    const availablePaymentTypes = [
        {
            id: 1,
            name: "Cryptomus",
            value: "cryptomus",
            icon: "/card.svg"
        },
        {
            id: 0,
            name: "Lava",
            value: "lava",
            icon: "/cloud.svg"
        }
    ];

    const [paymentType, setPaymentType] = useState(availablePaymentTypes[0]);
    const [depositAmount, setDepositAmount] = useState(0);

    const fetchPayments = async (page = meta.currentPage) => {
        try {
            const data = await getPayments(page, user.token);
            if (data?.data) {
                setPayments(data.data);
            }

            if (data?.meta) {
                setMeta({
                    currentPage: data.meta.currentPage,
                    pageSize: data.meta.pageSize,
                    totalPages: data.meta.totalPages,
                    totalItems: data.meta.totalItems
                });
            }
        } catch (error) {
            console.error('Error fetching payments:', error);
            toast.error(t('error-fetching-payments'));
        }
    };

    const handlePageChange = (newPage) => {
        setMeta(prevMeta => ({
            ...prevMeta,
            currentPage: newPage
        }));

        fetchPayments(newPage);
    };

    const handleDeposit = async (amount) => {
        try {
            if (!paymentType) {
                throw new Error('error-empty-payment-type');
            }
            if (!depositAmount || depositAmount <= 0) {
                throw new Error('error-empty-deposit-amount');
            }

            toast.info(t('notification-request-await'));
            const data = await depositUser(paymentType.value, amount, user.token);

            window.location.href = data.link;
        } catch (error) {
            toast.error(t(error.message));
        }
    };

    useEffect(() => {
        fetchPayments();
    }, []);

    return (
        <>
            <Helmet>
                <title>{t("page-title-wallet")}</title>
            </Helmet>
            <div>
                {/* <Breadcrumbs /> */}
                <div className="balance-deposit-form-wrapper">
                    <div className="balance-deposit-form">
                        <div className="container">
                            <h2 className="text-h2">{t('name-choose-payment-system')}</h2>
                            <div className="deposit-variants">
                                {availablePaymentTypes.map((item, index) => (
                                    <div className={"deposit-variants__item" + (paymentType.id === item.id ? " selected" : '')} key={item.id}>
                                        <input
                                            className="checkbox"
                                            type="radio"
                                            name="paymentType"
                                            id={'deposit_variant_' + index}
                                            value={item.id}
                                            checked={paymentType.id === item.id}
                                            onChange={(e) => setPaymentType(item)}
                                        />
                                        <label className="deposit-variants__wrapper" htmlFor={'deposit_variant_' + index}>
                                            <img className="deposit-variants__icon" src={item.icon} alt="" />
                                            <span className="deposit-variants__title">{item.name}</span>
                                            {
                                                item.id === 0 ?
                                                    <div className="deposit-variants__icons">
                                                        <img src="/visa_icon.svg" alt="" />
                                                        <img src="/mastercard_icon.svg" alt="" />
                                                    </div> :
                                                    item.id === 1 ?
                                                        <div className="deposit-variants__icons">
                                                            <img src="/eth_icon.svg" alt="" />
                                                            <img src="/btc_icon.svg" alt="" />
                                                            <img src="/usdt_icon.svg" alt="" />
                                                            <span className="green">30+ crypto</span>
                                                        </div> : ""
                                            }
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="balance-deposit-form">
                        <div className="container">
                            <h2 className="text-h2">{t('name-balance-deposit')}</h2>
                            <div>
                                <h3 className="text-h3" style={{ marginBottom: '10px' }}>{t("name-sum")}</h3>
                                <div className="input-wrapper">
                                    <input className="input" placeholder={t('name-input-a-sum')} type="number" min={1} max={100000} value={depositAmount} onChange={(e) => setDepositAmount(e.target.value)} />
                                </div>
                            </div>
                            <div className="balance-deposit-form__footer" style={{ marginTop: '30px' }}>
                                <div style={{ display: 'flex', alignItems: "center" }}>
                                    <h3 className="text-h3">{t("name-summary")}</h3>
                                    <h2 className="text-h2" style={{ marginLeft: '43px' }}>${depositAmount}</h2>
                                </div>
                                <button className="primary-btn" onClick={() => handleDeposit(depositAmount)}>
                                    {t('button-deposit')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="deposit-history">
                    <div className="container">
                        <h2 className="text-h2">{t('name-payments-history')}</h2>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{t('name-id')}</th>
                                    <th>{t('name-type')}</th>
                                    <th>{t('name-sum')}</th>
                                    <th>{t('name-status')}</th>
                                    <th>{t('name-date')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payments && payments.length > 0 ? payments.map((item) => (
                                    <tr
                                        className={
                                            item.status === 'Ожидает оплаты' ? 'await' :
                                                item.status === 'Ошибка' ? 'error' :
                                                    item.status === 'Оплачен' ? 'success' :
                                                        ''
                                        }
                                        key={item.id}
                                    >
                                        <td data-label={t('name-id')}>{item?.id}</td>
                                        <td data-label={t('name-type')}>{item?.type}</td>
                                        <td data-label={t('name-sum')}>{item?.amount.toFixed(2)}$</td>
                                        <td data-label={t('name-status')}>{item?.status}</td>
                                        <td data-label={t('name-date')}>{formatDate(item?.updatedAt)}</td>
                                    </tr>
                                )) : null}
                            </tbody>
                        </table>
                        {payments && meta.totalPages > 1 && (
                            <PaginationComponent data={payments} meta={meta} onPageChange={handlePageChange} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
