import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import HintComponent from '../../../components/HintComponent';
import DropdownButton from '../../../components/DropdownButton';

import getData from "../../../api/residential_proxy_pool_2/getData.js";
import generateProxyLists from "../../../api/residential_proxy_pool_2/generateProxyLists.js";

import getWhitelistEntries from "../../../api/residential_proxy_pool_2/getWhitelistEntries.js";
import addWhitelistEntry from "../../../api/residential_proxy_pool_2/addWhitelistEntry.js";
import removeWhitelistEntry from "../../../api/residential_proxy_pool_2/removeWhitelistEntry.js";

import getIPSkippingLists from "../../../api/residential_proxy_pool_2/getIPSkippingLists.js";
import addIPSkippingList from "../../../api/residential_proxy_pool_2/addIPSkippingList.js";
import deleteIPSkippingList from "../../../api/residential_proxy_pool_2/deleteIPSkippingList.js";
import updateIPSkippingList from "../../../api/residential_proxy_pool_2/updateIPSkippingList.js";

import { toast } from 'react-toastify';

import Preloader from "../../../components/Preloader/index.js";

import processErrors from '../../../utils/processErrors';

import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    AreaChart,
    Area,
} from "recharts";
import Popup from "../../../components/Popup/index.js";
import SelectComponent from "../../../components/SelectComponent/index.js";
import formatDate from "../../../utils/formatDate.js";

export const OrderResidentialProxyPool2Page = () => {
    const { t } = useTranslation();

    const user = useSelector((state) => state.user);

    const [isOpenWhitelistPopup, setIsOpenWhitelistPopup] = useState(false);
    const [isOpenIpskippingPopup, setIsOpenIpskippingPopup] = useState(false);

    const [currentTab, setCurrentTab] = useState('tab-configuration');

    const [residentialProxyData, setResidentialProxyData] = useState();
    const [trafficStats, setTrafficStats] = useState();
    const [selectedTrafficStatsTab, setSelectedTrafficStatsTab] = useState("resource-using");
    const [selectedTrafficUnit, setSelectedTrafficUnit] = useState({
        "name": "GB",
        "value": "gb",
    });
    const available_traffic_units = [
        {
            "name": "MB",
            "value": "mb",
        },
        {
            "name": "GB",
            "value": "gb",
        }
    ]
    const [trafficStatsStartDate, setTrafficStatsStartDate] = useState('');
    const [trafficStatsEndDate, setTrafficStatsEndDate] = useState('');

    const [selectedCountry, setSelectedCountry] = useState();
    const [selectedCity, setSelectedCity] = useState();
    const [selectedProtocol, setSelectedProtocol] = useState();
    const [selectedRotation, setSelectedRotation] = useState();
    const [selectedHostname, setSelectedHostname] = useState();
    const [selectedPort, setSelectedPort] = useState();
    const [selectedUsername, setSelectedUsername] = useState();
    const [selectedPassword, setSelectedPassword] = useState();
    const [selectedFormat, setSelectedFormat] = useState();
    const [selectedQuantity, setSelectedQuantity] = useState(10);
    const [selectedFormattedQuantity, setSelectedFormattedQuantity] = useState(10);
    const [selectedWhitelist, setSelectedWhitelist] = useState();

    const [newWhitelistEntrySelectedHighEndPool, setNewWhitelistEntrySelectedHighEndPool] = useState("");
    const [newWhitelistEntrySelectedSkipStatic, setNewWhitelistEntrySelectedSkipStatic] = useState("");
    const [newWhitelistEntrySelectedCountry, setNewWhitelistEntrySelectedCountry] = useState("");
    const [newWhitelistEntrySelectedState, setNewWhitelistEntrySelectedState] = useState("");
    const [newWhitelistEntrySelectedType, setNewWhitelistEntrySelectedType] = useState("");
    const [newWhitelistEntrySelectedRotation, setNewWhitelistEntrySelectedRotation] = useState("");
    const [newWhitelistEntrySelectedIPSkipping, setNewWhitelistEntrySelectedIPSkipping] = useState("");
    const [newWhitelistEntryConfiguration, setNewWhitelistEntryConfiguration] = useState("");

    const [newWhitelistEntrySelectedIP, setNewWhitelistEntrySelectedIP] = useState("");
    const [newWhitelistEntrySelectedPort, setNewWhitelistEntrySelectedPort] = useState("");

    const [newIPSkippingListTitle, setNewIPSkippingListTitle] = useState("");

    const [proxyLists, setProxyLists] = useState([]);
    const [formattedProxyLists, setFormattedProxyLists] = useState([]);
    const [availableProtocols, setAvailableProtocols] = useState([]);
    const [availableHostnames, setAvailableHostnames] = useState([]);
    const [availablePorts, setAvailablePorts] = useState([]);
    const [availableWhitelist, setAvailableWhitelist] = useState([]);
    const [availableIPSkippingList, setAvailableIPSkippingList] = useState([]);

    const downloadFile = (data, fileName, fileType) => {
        const blob = new Blob([data], { type: fileType });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);

        toast.success(t('name-download-success'));
    };

    const downloadCSV = () => {
        const csvData = proxyLists.map(item => item).join('\n');
        downloadFile(csvData, 'proxyList.csv', 'text/csv');
    };

    const downloadTXT = () => {
        const txtData = proxyLists.join('\n');
        downloadFile(txtData, 'proxyList.txt', 'text/plain');
    };

    const downloadFormattedCSV = () => {
        const csvData = formattedProxyLists.map(item => item).join('\n');
        downloadFile(csvData, 'proxyList.csv', 'text/csv');
    };

    const downloadFormattedTXT = () => {
        const txtData = formattedProxyLists.join('\n');
        downloadFile(txtData, 'proxyList.txt', 'text/plain');
    };

    const availableProxylistDownloadOptions = [
        { label: '.CSV', action: downloadCSV },
        { label: '.TXT', action: downloadTXT },
    ];
    const availableFormattedProxylistDownloadOptions = [
        { label: '.CSV', action: downloadFormattedCSV },
        { label: '.TXT', action: downloadFormattedTXT },
    ];

    // IP skipping variables

    const [ipSkippingEditItem, setIpSkippingEditItem] = useState({});

    const handleIPSkippingEditItemAddRange = () => {
        const newRange = '0.0.0.0/0';

        const updatedRanges = [...(ipSkippingEditItem?.items || []), { "ip_range": newRange }];

        setIpSkippingEditItem({
            ...ipSkippingEditItem,
            items: updatedRanges
        });
    };

    const handleIpSkippingEditItem = (item) => {
        setIsOpenIpskippingPopup(true);
        setIpSkippingEditItem(item);
    };

    const handleNewWhitelistEntryResetOptions = () => {
        setNewWhitelistEntrySelectedHighEndPool(false)
        setNewWhitelistEntrySelectedSkipStatic(false)
        setNewWhitelistEntrySelectedCountry("")
        setNewWhitelistEntrySelectedState("")
        setNewWhitelistEntrySelectedRotation("")
        setNewWhitelistEntrySelectedType("")
        setNewWhitelistEntrySelectedIP("")
        setNewWhitelistEntrySelectedPort("")
    };


    const handleIPSkippingEditItemRemoveRange = (index) => {
        const updatedRanges = ipSkippingEditItem.items.filter((_, i) => i !== index);

        if (updatedRanges.length > 0) {
            setIpSkippingEditItem({
                ...ipSkippingEditItem,
                items: updatedRanges
            });
        }
    };

    const convertToMB = (valueInGB) => valueInGB * 1024;
    const filterByDateRange = (data, startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        return data.filter(item => {
            const itemDate = new Date(item.createdAt);
            return itemDate >= start && itemDate <= end;
        });
    };

    useEffect(() => {
        if (selectedWhitelist) {
            let formatted_proxy_lists = []

            for (let index = 0; index < selectedFormattedQuantity; index++) {
                formatted_proxy_lists.push(`${availableHostnames[0].value}:${selectedWhitelist.value.port}`)
            }

            setFormattedProxyLists(formatted_proxy_lists)
        }
    }, [selectedWhitelist, selectedFormattedQuantity]);

    useEffect(() => {
        if (residentialProxyData?.traffic_stats && residentialProxyData?.traffic_stats?.length > 0) {
            const trafficStatsDateFiltered = filterByDateRange(residentialProxyData?.traffic_stats, trafficStatsStartDate, trafficStatsEndDate);
            const resourceUsage = trafficStatsDateFiltered.map((item, index, array) => {
                const currentUnit = selectedTrafficUnit.value;

                const currentTrafficBalance = currentUnit === 'mb' ? convertToMB(item.traffic_balance) : item.traffic_balance;

                if (index === 0) {
                    return {
                        ...item,
                        traffic_balance: 0,
                        createdAt: formatDate(item?.createdAt, "date-without-year")
                    };
                } else {
                    const previousItem = array[index - 1];
                    const previousTrafficBalance = currentUnit === 'mb' ? convertToMB(previousItem.traffic_balance) : previousItem.traffic_balance;
                    return {
                        ...item,
                        traffic_balance: Math.abs((previousTrafficBalance - currentTrafficBalance).toFixed(2)),
                        createdAt: formatDate(item?.createdAt, "date-without-year")
                    };
                }
            });

            if (selectedTrafficStatsTab === "resource-using") {
                setTrafficStats(resourceUsage);
            } else if (selectedTrafficStatsTab === "resource-left") {
                const formatted_traffic_stats = trafficStatsDateFiltered?.map((item) => {
                    const currentTrafficBalance = selectedTrafficUnit === 'mb' ? convertToMB(item.traffic_balance) : item.traffic_balance;
                    return {
                        traffic_balance: currentTrafficBalance,
                        createdAt: formatDate(item?.createdAt, "date-without-year")
                    };
                }) || [];
                setTrafficStats(formatted_traffic_stats);
            }
        }
    }, [residentialProxyData, selectedTrafficStatsTab, selectedTrafficUnit, trafficStatsStartDate, trafficStatsEndDate]);

    const fetchResidentialProxyData = async () => {
        const data = await getData(user.token);
        if (data?.message === 'Forbidden') {
            window.location.href = '/login'
        }
        setResidentialProxyData(data)
        setSelectedCountry(data?.available_countries?.countries[0])
        setSelectedUsername(data?.available_usernames ? data?.available_usernames[0] : "")
        setSelectedPassword(data?.available_passwords ? data?.available_passwords[0] : "")
        setSelectedCity(data?.available_countries?.countries[0]
            ?.cities?.options.map((item) => ({
                name: item.name,
                value: item.code
            }))[0])
        setSelectedRotation(data?.available_rotations ? data?.available_rotations[0] : "")
        setSelectedFormat(data?.available_formats ? data?.available_formats[0] : "")
        const formatted_traffic_stats = data?.traffic_stats?.map((item) => ({
            traffic_balance: item?.traffic_balance,
            createdAt: formatDate(item?.createdAt, "date-without-year")
        })) || [];
        setTrafficStats(formatted_traffic_stats)

        const formatDateForInput = (dateString) => {
            try {
                const date = new Date(dateString);
                return date.toISOString().split('T')[0];
            } catch (error) {
                return "";
            }
        };

        setTrafficStatsStartDate(formatDateForInput(data?.traffic_stats ? data?.traffic_stats[0]?.createdAt : ""))
        setTrafficStatsEndDate(formatDateForInput(data?.traffic_stats ? data?.traffic_stats[data?.traffic_stats.length - 1]?.createdAt : ""))
        if (data?.whitelist && data?.whitelist?.length > 0) {
            setAvailableWhitelist(data?.whitelist)
        }
        if (data?.ips_skipping && data?.ips_skipping?.length > 0) {
            setAvailableIPSkippingList(data?.ips_skipping)
        }
    };

    useEffect(() => {
        if (residentialProxyData?.entry_nodes) {
            const hostnames = residentialProxyData.entry_nodes.map((item) => ({ name: item.dns, value: item.dns }));
            setAvailableHostnames(hostnames);
            if (hostnames.length > 0) {
                setSelectedHostname(hostnames[0]);
            }
        }
    }, [residentialProxyData]);

    useEffect(() => {
        if (selectedHostname && residentialProxyData) {
            const protocols = residentialProxyData.entry_nodes
                .filter((item) => item?.dns === selectedHostname?.value)
                .flatMap((item) => item?.ports || [])
                .map((item) => ({ name: item.name, value: item.name }));
            setAvailableProtocols(protocols);
            if (protocols.length > 0) {
                setSelectedProtocol(protocols[0]);
            }
        }
    }, [selectedHostname, residentialProxyData]);

    useEffect(() => {
        if (selectedProtocol && selectedHostname && residentialProxyData) {
            const ports = residentialProxyData.entry_nodes
                .filter((item) => item?.dns === selectedHostname?.value)
                .flatMap((item) => item?.ports || [])
                .filter((item) => item?.name === selectedProtocol?.value)
                .map((item) => ({ name: item.port, value: item.port }));
            setAvailablePorts(ports);
            if (ports.length > 0) {
                setSelectedPort(ports[0]);
            }
        }
    }, [selectedProtocol, selectedHostname, residentialProxyData]);
    useEffect(() => {
        let configuration_value = "";

        configuration_value += [
            residentialProxyData?.available_countries?.prefix && newWhitelistEntrySelectedCountry?.code
                ? `${residentialProxyData.available_countries.prefix}${newWhitelistEntrySelectedCountry.code}`
                : "",
            newWhitelistEntrySelectedCountry?.cities?.prefix && newWhitelistEntrySelectedState?.value
                ? `${newWhitelistEntrySelectedCountry.cities.prefix}${newWhitelistEntrySelectedState.value}`
                : ""
        ].join('');

        if (newWhitelistEntrySelectedSkipStatic) {
            configuration_value += "_skipstatic-1";
        }

        if (newWhitelistEntrySelectedHighEndPool) {
            configuration_value += "_streaming-1";
        }

        setNewWhitelistEntryConfiguration(configuration_value)
    }, [
        newWhitelistEntrySelectedSkipStatic,
        newWhitelistEntrySelectedHighEndPool,
        newWhitelistEntrySelectedCountry,
        newWhitelistEntrySelectedState,
        newWhitelistEntrySelectedIP,
        newWhitelistEntrySelectedPort,
        newWhitelistEntrySelectedType,
        newWhitelistEntrySelectedIPSkipping
    ]);

    const fetchGenerateProxyLists = async () => {
        try {
            if (!user.token) {
                window.location.href = '/login';
                return;
            }

            const location = [
                residentialProxyData?.available_countries?.prefix && selectedCountry?.code
                    ? residentialProxyData.available_countries.prefix + selectedCountry.code
                    : "",
                selectedCountry?.cities?.prefix && selectedCity?.value
                    ? selectedCountry.cities.prefix + selectedCity.value
                    : ""
            ].join('');


            const data = await generateProxyLists({
                format: selectedFormat.value,
                hostname: selectedHostname.value,
                port: selectedProtocol.value,
                rotation: selectedRotation.value,
                location: location,
                proxy_count: selectedQuantity
            }, user.token);

            if (data?.message === 'Forbidden') {
                window.location.href = '/login';
                return;
            }

            if (data?.message === 'Server error') {
                console.error('Server error');
                return;
            }

            setProxyLists(data);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleIPSkippingListAdd = async () => {
        const data = await addIPSkippingList({
            title: newIPSkippingListTitle
        }, user.token);

        if (data?.message === 'Forbidden') {
            window.location.href = '/login'
        }
        if (data?.message === 'Server error') {
            toast.error(t('notification-ipskipping-add-error'));
            return
        }

        setNewIPSkippingListTitle('');
        toast.success(t('notification-ipskipping-add-success'));

        const ipskipping_data = await getIPSkippingLists(user.token);
        setAvailableIPSkippingList(ipskipping_data)
    };

    const handleIPSkippingListUpdate = async () => {
        const data = await updateIPSkippingList({
            title: newIPSkippingListTitle,
            items: ipSkippingEditItem?.items
        }, user.token);
        if (data?.message === 'Forbidden') {
            window.location.href = '/login'
        }
        if (data?.message === 'Server error') {
            toast.error(t('name-ipskipping-update-error'));
            return
        }
        toast.success(t('name-ipskipping-update-success'));

        const ipskipping_data = await getIPSkippingLists(user.token);
        setAvailableIPSkippingList(ipskipping_data);
    };

    const handleIPSkippingListRemove = async (hash) => {
        const isConfirmed = window.confirm(t("name-confirm"));
        if (!isConfirmed) {
            return;
        }

        const data = await deleteIPSkippingList({
            hash: hash
        }, user.token);
        if (data?.message === 'Forbidden') {
            window.location.href = '/login'
        }
        if (data?.message === 'Server error') {
            toast.error(t('name-ipskipping-remove-error'));
            return
        }
        toast.success(t('name-ipskipping-remove-success'));

        const ipskipping_data = await getIPSkippingLists(user.token);
        setAvailableIPSkippingList(ipskipping_data)
    };

    const handleWhitelistEntryAdd = async () => {
        try {
            var params = {}

            if (newWhitelistEntrySelectedIP) {
                params.ip = newWhitelistEntrySelectedIP
            }

            if (newWhitelistEntrySelectedPort) {
                params.port = newWhitelistEntrySelectedPort?.value
            }

            if (newWhitelistEntryConfiguration) {
                params.configuration = newWhitelistEntryConfiguration
            }

            const response = await addWhitelistEntry(params, user.token);
            const responseJson = await response.json();

            if (!response.ok) {
                const errorMessage = processErrors(responseJson);
                throw new Error(errorMessage);
            }

            setIsOpenWhitelistPopup(false);
            handleNewWhitelistEntryResetOptions()
            toast.success(t('name-whitelist-add-success'));

            const whitelist_data = await getWhitelistEntries(user.token);
            setAvailableWhitelist(whitelist_data);
        } catch (error) {
            toast.error(t(error.message));
        }
    };

    const handleWhitelistEntryRemove = async (hash) => {
        const isConfirmed = window.confirm(t("name-confirm"));
        if (!isConfirmed) {
            return;
        }

        const data = await removeWhitelistEntry({
            whitelist_entry_hash: hash
        }, user.token);
        if (data?.message === 'Forbidden') {
            window.location.href = '/login'
        }
        if (data?.message === 'Server error') {
            toast.error(t('name-whitelist-remove-error'));
            return
        }
        toast.success(t('name-whitelist-remove-success'));

        const whitelist_data = await getWhitelistEntries(user.token);
        setAvailableWhitelist(whitelist_data);
    };

    useEffect(() => {
        fetchResidentialProxyData();
    }, []);

    useEffect(() => {
        setSelectedCity(selectedCountry?.cities?.options.map((item) => ({
            name: item.name,
            value: item.code
        }))[0])
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedFormat && selectedHostname && selectedPort && selectedRotation && selectedCountry && selectedQuantity) {
            fetchGenerateProxyLists();
        }
    }, [selectedFormat, selectedHostname, selectedPort, selectedRotation, selectedCountry, selectedCity, selectedQuantity]);

    const handleCopy = () => {
        const textareaValue = proxyLists.join('\n');
        navigator.clipboard.writeText(textareaValue).then(() => {
            toast.success(t('name-copy-success'));
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    const handleFormattedCopy = () => {
        const textareaValue = formattedProxyLists.join('\n');
        navigator.clipboard.writeText(textareaValue).then(() => {
            toast.success(t('name-copy-success'));
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    const CustomTooltip2 = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <>
                    <div className="graph-tooltip">
                        <div className="graph-tooltip__value">{payload[0].value}</div>
                        <div className="graph-tooltip__name">{selectedTrafficUnit?.name}</div>
                    </div>
                </>
            );
        }
    };

    return (
        <>
            <Helmet>
                <title>{t("page-title-account")}</title>
            </Helmet>
            {
                !residentialProxyData ? <Preloader></Preloader>
                    :
                    <>
                        <div className="tabs-wrapper">
                            <div
                                className={"tab-item " + (currentTab === 'tab-configuration' ? 'selected' : "")}
                                onClick={() => setCurrentTab('tab-configuration')}
                            >
                                {t('name-configuration')}
                            </div>
                            <div
                                className={"tab-item " + (currentTab === 'tab-whitelist' ? 'selected' : "")}
                                onClick={() => setCurrentTab('tab-whitelist')}
                            >
                                {t('name-whitelist')}
                            </div>
                            <div
                                className={"tab-item " + (currentTab === 'tab-ipskipping' ? 'selected' : "")}
                                onClick={() => setCurrentTab('tab-ipskipping')}
                            >
                                {t('name-ipskipping')}
                            </div>
                        </div>
                        {
                            currentTab === 'tab-configuration' &&
                                residentialProxyData &&
                                residentialProxyData?.available_countries &&
                                residentialProxyData?.available_rotations &&
                                residentialProxyData?.traffic_available &&
                                residentialProxyData?.available_formats ?
                                <div className="proxylist-wrapper">
                                    <div className="grid-wrapper">
                                        {
                                            residentialProxyData &&
                                            residentialProxyData?.available_countries &&
                                            <div className="form">
                                                <div className="form-header">
                                                    <h2 className="text-h2">{t('name-proxy-access')}</h2>
                                                </div>
                                                <div className="grid-wrapper">
                                                    <SelectComponent label={t('name-country-or-region')} items={residentialProxyData.available_countries.countries} selectedItem={selectedCountry} onItemSelected={setSelectedCountry}></SelectComponent>
                                                    <SelectComponent label={t('name-proxy-host-name')} items={availableHostnames} selectedItem={selectedHostname} onItemSelected={setSelectedHostname}></SelectComponent>
                                                </div>
                                                <div className="grid-wrapper">
                                                    {
                                                        selectedCountry?.cities?.options ?
                                                            <SelectComponent
                                                                label={t('name-city')}
                                                                items={
                                                                    selectedCountry?.cities?.options.map((item) => ({
                                                                        name: item.name,
                                                                        value: item.code
                                                                    }))
                                                                }
                                                                disabled={!selectedCountry?.cities?.options || selectedCountry?.cities?.options?.length <= 0}
                                                                selectedItem={selectedCity}
                                                                onItemSelected={setSelectedCity}
                                                            />
                                                            : ''
                                                    }
                                                    <SelectComponent label={t('name-port-proxy')} items={availablePorts} selectedItem={selectedPort} onItemSelected={setSelectedPort}></SelectComponent>
                                                </div>
                                                <div className="grid-wrapper">
                                                    <SelectComponent label={t('name-protocol')} items={availableProtocols} selectedItem={selectedProtocol} onItemSelected={setSelectedProtocol}></SelectComponent>
                                                    <SelectComponent label={t('name-username')} items={residentialProxyData.available_usernames} selectedItem={selectedUsername} onItemSelected={setSelectedUsername}></SelectComponent>
                                                </div>
                                                <div className="grid-wrapper">
                                                    <SelectComponent label={t('name-rotation')} items={residentialProxyData.available_rotations} selectedItem={selectedRotation} onItemSelected={setSelectedRotation}></SelectComponent>
                                                    <SelectComponent label={t('name-password')} items={residentialProxyData.available_passwords} selectedItem={selectedPassword} onItemSelected={setSelectedPassword}></SelectComponent>
                                                </div>
                                                <label className="label">{t('name-curl-example')}</label>
                                                <div className="input-wrapper_fade">
                                                    <input className="input" type="text" value={`$ curl -v -x http://${selectedUsername?.value}:${selectedPassword?.value}@${selectedHostname?.value}:${12321} -L https://ipv4.icanhazip.com`} disabled />
                                                </div>
                                            </div>
                                        }
                                        <div className="form">
                                            <div className="form-header">
                                                <h2 className="text-h2">{t('name-formatted-proxy-list')}</h2>
                                            </div>
                                            <textarea
                                                className="textarea"
                                                disabled
                                                value={proxyLists.join('\n')}
                                                onChange={(e) => (setProxyLists(e.target.value))}
                                                name=""
                                                id=""
                                            ></textarea>
                                            <div className="grid-wrapper" style={{alignItems: "center"}}>
                                                <div className="primary-btn" onClick={handleCopy}>{t('name-copy')}</div>
                                                <DropdownButton title={t("button-downloads-list")} options={availableProxylistDownloadOptions} />
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-format")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <SelectComponent items={residentialProxyData.available_formats} selectedItem={selectedFormat} onItemSelected={setSelectedFormat}></SelectComponent>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-count")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="input-wrapper_fade">
                                                        <input className="input" value={selectedQuantity} onChange={(e) => (setSelectedQuantity(e.target.value))} min={1} max={10000} type="number" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        residentialProxyData?.traffic_available ?
                                            <div className="form">
                                                <div className="form-header">
                                                    <h2 className="text-h2">{t('name-available-traffic')}</h2>
                                                </div>
                                                <div className="input-wrapper_fade">
                                                    <input className="input traffic-left" style={{textAlign: "center"}} value={Number(residentialProxyData.traffic_available).toFixed(3) + ' GB'} disabled={true} type="text" />
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    <div className="form">
                                        <div className="form-header">
                                            <div>
                                                <h2 className="text-h2">{t('name-statistics')}</h2>
                                                <div className="graph-tabs">
                                                    <div
                                                        className={`graph-tabs__item ${selectedTrafficStatsTab === "resource-using" ? "selected" : ""}`}
                                                        onClick={() => setSelectedTrafficStatsTab("resource-using")}
                                                    >
                                                        {t('name-resource-using')}
                                                    </div>
                                                    <div
                                                        className={`graph-tabs__item ${selectedTrafficStatsTab === "resource-left" ? "selected" : ""}`}
                                                        onClick={() => setSelectedTrafficStatsTab("resource-left")}
                                                    >
                                                        {t('name-value-resources-left')}
                                                    </div>
                                                </div>

                                            </div>
                                            <div>
                                                <SelectComponent items={available_traffic_units} selectedItem={selectedTrafficUnit} onItemSelected={setSelectedTrafficUnit}></SelectComponent>
                                                <div className="input-wrapper_fade date-wrapper">
                                                    <input className="input" type="date" id="start-date" value={trafficStatsStartDate} onChange={(e) => setTrafficStatsStartDate(e.target.value)} />
                                                    <input className="input" type="date" id="end-date" value={trafficStatsEndDate} onChange={(e) => setTrafficStatsEndDate(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            trafficStats && trafficStats?.length > 0 ?
                                                <ResponsiveContainer width="100%" height={250}>
                                                    <AreaChart
                                                        data={trafficStats}
                                                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                                                    >
                                                        <defs>
                                                            <linearGradient id="colorDifficulty" x1="0" y1="0" x2="0" y2="1">
                                                                <stop offset="5%" stopColor="#A038AA" stopOpacity={0.15} />
                                                                <stop offset="95%" stopColor="#A038AA" stopOpacity={0} />
                                                            </linearGradient>
                                                        </defs>
                                                        <CartesianGrid />
                                                        <XAxis dataKey="createdAt" label={{ value: '', position: 'insideBottomRight', offset: 0 }} />
                                                        <YAxis dataKey="traffic_balance" label={{ value: selectedTrafficUnit?.name, angle: -90, position: 'insideLeft' }} domain={[0, (Math.max(...trafficStats.map(item => item.traffic_balance)) + (Math.max(...trafficStats.map(item => item.traffic_balance)) * 0.1))]} />
                                                        <Tooltip content={<CustomTooltip2 />} />
                                                        <Area
                                                            type="monotone"
                                                            dataKey="traffic_balance"
                                                            stroke="#4E9AA5"
                                                            fillOpacity={1}
                                                            strokeWidth={2}
                                                            fill="transparent"
                                                        />
                                                    </AreaChart>
                                                </ResponsiveContainer>
                                                : ''
                                        }
                                    </div>
                                </div>
                                : ''
                        }
                        {
                            currentTab === 'tab-whitelist' ?
                                <>
                                    <div className="whitelist-wrapper">
                                        <div className="form">
                                            <div>
                                                <h2 className="text-h2">{t("name-ip-adresses-in-whitelist")}</h2>
                                                <a className="link" href="/support/whitelisting-ips" target="_blank" rel="noopener noreferrer">
                                                    <b>{t("name-guide")}</b>
                                                </a>
                                                <table className="table_fade">
                                                    <thead>
                                                        <th>{t("name-ip")}</th>
                                                        <th>{t("name-type")}</th>
                                                        <th>{t("name-port")}</th>
                                                        <th>{t("name-configuration")}</th>
                                                        <th>{t("name-actions")}</th>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            availableWhitelist && availableWhitelist?.length > 0 ?
                                                                availableWhitelist.map((item) => (
                                                                    <tr>
                                                                        <td data-label={t("name-ip")}>{item?.ip}</td>
                                                                        <td data-label={t("name-type")}>{item?.type}</td>
                                                                        <td data-label={t("name-port")}>{item?.port}</td>
                                                                        <td data-label={t("name-configuration")}>{item?.configuration}</td>
                                                                        <td data-label={t("name-actions")}>
                                                                            <button className="delete-button" onClick={(e) => (handleWhitelistEntryRemove(item?.hash))}>{t("button-delete")}</button>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                :
                                                                <tr>
                                                                    <td colSpan="6" style={{textAlign: "center", color: "rgb(133, 133, 133)"}}>{t("name-no-data")}</td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                                <div className="primary-btn" onClick={(e) => (setIsOpenWhitelistPopup(true))}>{t("button-add")}</div>
                                            </div>
                                        </div>
                                        <div className="form">
                                            <h2 className="text-h2">{t("name-formatted-proxy-list-server")}</h2>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-whitelist")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <SelectComponent
                                                        selectedItem={selectedWhitelist}
                                                        items={availableWhitelist.map((item) => ({ name: `${item.ip} (${item.type})`, value: item }))}
                                                        disabled={!availableWhitelist || availableWhitelist?.length <= 0}
                                                        onItemSelected={setSelectedWhitelist}></SelectComponent>
                                                </div>
                                            </div>
                                            <label className="label" htmlFor="">{t("name-ip-port")}</label>
                                            <textarea className="textarea" disabled value={formattedProxyLists.join('\n')} onChange={(e) => setFormattedProxyLists(e.target.value)} name="" id=""></textarea>
                                            <div className="form-section">
                                                <div className="form-section__content">
                                                    <div className="grid-wrapper">
                                                        <div className="primary-btn" onClick={handleFormattedCopy}>{t('name-copy')}</div>
                                                        <DropdownButton title={t("button-downloads-list")} options={availableFormattedProxylistDownloadOptions} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-count")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="input-wrapper_fade">
                                                        <input
                                                            className="input"
                                                            value={selectedFormattedQuantity}
                                                            onChange={(e) => {
                                                                const value = Math.min(e.target.value, 10000);
                                                                setSelectedFormattedQuantity(value);
                                                            }}
                                                            type="number"
                                                            max="10000"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Popup title={t("popup-title-whitelist-entry-create")} trigger={isOpenWhitelistPopup} setTrigger={setIsOpenWhitelistPopup}>
                                        <div className="popup__content">
                                            <div className="form-section">
                                                <div className="form-section__content">
                                                    <a className="link" href="/support/pool-1-terms" target="_blank" rel="noopener noreferrer">
                                                        <b>{t("name-pool-1-terms")}</b>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3"><b>{t("name-configuration")}</b></h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="flex-wrapper space-between">
                                                        <div className="flex-wrapper">
                                                            <div className="checkbox-wrapper">
                                                                <input
                                                                    className="switch"
                                                                    type="checkbox"
                                                                    checked={newWhitelistEntrySelectedHighEndPool}
                                                                    onChange={(e) => setNewWhitelistEntrySelectedHighEndPool(e.target.checked)}
                                                                    id="high-end-pool-checkbox"
                                                                />
                                                                <label className="label" htmlFor="high-end-pool-checkbox">
                                                                    <HintComponent
                                                                        title={t('name-high-end-pool')}
                                                                        content={t('hint-high-end-pool')}
                                                                    />
                                                                </label>
                                                            </div>
                                                            <div className="checkbox-wrapper">
                                                                <input
                                                                    className="switch"
                                                                    type="checkbox"
                                                                    checked={newWhitelistEntrySelectedSkipStatic}
                                                                    onChange={(e) => setNewWhitelistEntrySelectedSkipStatic(e.target.checked)}
                                                                    id="skip-static-checkbox"
                                                                />
                                                                <label className="label" htmlFor="skip-static-checkbox">
                                                                    {t('name-skip-asp-static')}
                                                                </label>
                                                            </div>

                                                        </div>
                                                        <button className="primary-btn" onClick={handleNewWhitelistEntryResetOptions}>{t("button-reset-options")}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__content">
                                                    <div className="grid-wrapper">
                                                        <SelectComponent label={t('name-country-or-region')} disabled={!residentialProxyData?.available_countries?.countries || residentialProxyData.available_countries.countries?.length <= 0} items={residentialProxyData.available_countries.countries} selectedItem={newWhitelistEntrySelectedCountry} onItemSelected={setNewWhitelistEntrySelectedCountry}></SelectComponent>
                                                        <SelectComponent
                                                            label={t('name-city')}
                                                            items={
                                                                newWhitelistEntrySelectedCountry?.cities?.options ? newWhitelistEntrySelectedCountry.cities.options.map((item) => ({
                                                                    name: item.name,
                                                                    value: item.code
                                                                })) : []
                                                            }
                                                            disabled={!newWhitelistEntrySelectedCountry?.cities?.options || newWhitelistEntrySelectedCountry?.cities?.options?.length <= 0}
                                                            selectedItem={newWhitelistEntrySelectedState}
                                                            onItemSelected={setNewWhitelistEntrySelectedState}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__content">
                                                    <div className="grid-wrapper">
                                                        <SelectComponent label={t('name-rotation')} disabled={!residentialProxyData?.available_rotations || residentialProxyData?.available_rotations?.length <= 0} items={residentialProxyData.available_rotations} selectedItem={newWhitelistEntrySelectedRotation} onItemSelected={setNewWhitelistEntrySelectedRotation}></SelectComponent>
                                                        <SelectComponent label={t('name-protocol') + "*"} disabled={!availableProtocols || availableProtocols?.length <= 0} items={availableProtocols} selectedItem={newWhitelistEntrySelectedType} onItemSelected={setNewWhitelistEntrySelectedType}></SelectComponent>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__content">
                                                    <div className="grid-wrapper">
                                                        <SelectComponent
                                                            label={t('name-ipskipping')}
                                                            disabled={!availableIPSkippingList || availableIPSkippingList?.length <= 0}
                                                            items={availableIPSkippingList.map((item) => ({ name: item.title, value: item.hash }))}
                                                            selectedItem={newWhitelistEntrySelectedIPSkipping}
                                                            onItemSelected={setNewWhitelistEntrySelectedIPSkipping}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3"><b>{t("name-options")}</b></h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="form-section">
                                                        <div className="form-section__header">
                                                            <h3 className="text-h3">{t("name-ip")}*</h3>
                                                        </div>
                                                        <div className="form-section__content">
                                                            <div className="input-wrapper_fade">
                                                                <input className="input" placeholder={t("name-ip")} type="text" value={newWhitelistEntrySelectedIP} onChange={(e) => (setNewWhitelistEntrySelectedIP(e.target.value))} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <SelectComponent label={t('name-port') + "*"} items={availablePorts} selectedItem={newWhitelistEntrySelectedPort} onItemSelected={setNewWhitelistEntrySelectedPort}></SelectComponent>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-configuration")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="input-wrapper">
                                                        <input
                                                            className="input"
                                                            type="text"
                                                            disabled={true}
                                                            value={newWhitelistEntryConfiguration}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="popup__footer">
                                            <button className="primary-btn" disabled={!newWhitelistEntrySelectedIP || !newWhitelistEntrySelectedPort || !newWhitelistEntrySelectedType} onClick={handleWhitelistEntryAdd}>{t("button-whitelist-entry-add")}</button>
                                        </div>
                                    </Popup>
                                </>
                                : ''
                        }
                        {
                            currentTab === 'tab-ipskipping' ?
                                <>
                                    <div className="ipskipping-wrapper">
                                        <div className="form">
                                            <div>
                                                <h2 className="text-h2">{t("name-ip-adresses-in-whitelist")}</h2>
                                                <table className="table_fade">
                                                    <thead>
                                                        <th>{t("name-title")}</th>
                                                        <th>{t("name-actions")}</th>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            availableIPSkippingList && availableIPSkippingList?.length > 0 ?
                                                                availableIPSkippingList.map((item) => (
                                                                    <tr>
                                                                        <td data-label={t("name-title")} style={{width: "100%"}}>{item?.title}</td>
                                                                        <td data-label={t("name-actions")}>
                                                                            <div className="flex-wrapper" style={{gap: "8px"}}>
                                                                                <button className="delete-button" onClick={(e) => (handleIPSkippingListRemove(item?.hash))}>{t("button-delete")}</button>
                                                                                <button className="edit-button" onClick={(e) => (handleIpSkippingEditItem(item))}>{t("button-edit")}</button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                :
                                                                <tr>
                                                                    <td colSpan="3" style={{textAlign: "center", color: "rgb(133, 133, 133)"}}>{t("name-no-data")}</td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="form">
                                            <h2 className="text-h2">
                                                {t("name-create-new-list")}
                                            </h2>
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-title")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="input-wrapper_fade">
                                                        <input className="input" value={newIPSkippingListTitle} placeholder={t('name-title')} onChange={(e) => (setNewIPSkippingListTitle(e.target.value))} type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="primary-btn" disabled={!newIPSkippingListTitle || newIPSkippingListTitle?.length <= 1} onClick={(e) => (handleIPSkippingListAdd())}>{t("name-create")}</button>
                                            <br />
                                            <a className="link" href="/support/ip-skipping" target="_blank" rel="noopener noreferrer">
                                                <b>{t("name-guide")}</b>
                                            </a>
                                        </div>
                                    </div>
                                    <Popup title={t("popup-title-ipskipping-entry-edit")} trigger={isOpenIpskippingPopup} setTrigger={setIsOpenIpskippingPopup}>
                                        <div className="popup__content">
                                            <div className="form-section">
                                                <div className="form-section__header">
                                                    <h3 className="text-h3">{t("name-title")}</h3>
                                                </div>
                                                <div className="form-section__content">
                                                    <div className="input-wrapper_fade">
                                                        <input
                                                            className="input"
                                                            value={ipSkippingEditItem?.title || ''}
                                                            placeholder={t('name-title')}
                                                            onChange={(e) => setIpSkippingEditItem({
                                                                ...ipSkippingEditItem,
                                                                title: e.target.value
                                                            })}
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {ipSkippingEditItem?.items && ipSkippingEditItem.items.map((item, index) => (
                                                <div className="form-section" key={index}>
                                                    <div className="form-section__header">
                                                        <h3 className="text-h3">{t("name-ip-range")}</h3>
                                                    </div>
                                                    <div className="form-section__content">
                                                        <div className="flex-wrapper">
                                                            <div className="input-wrapper_fade">
                                                                <input
                                                                    className="input"
                                                                    value={item?.ip_range}
                                                                    placeholder={'1.0.0.1/23'}
                                                                    onChange={(e) => {
                                                                        const updatedRanges = [...ipSkippingEditItem.items];
                                                                        updatedRanges[index] = e.target.value;
                                                                        setIpSkippingEditItem({
                                                                            ...ipSkippingEditItem,
                                                                            items: updatedRanges
                                                                        });
                                                                    }}
                                                                    type="text"
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="delete-button"
                                                                onClick={() => handleIPSkippingEditItemRemoveRange(index)}
                                                                disabled={ipSkippingEditItem.items.length <= 1}
                                                            >
                                                                {t("button-delete")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <button className="primary-btn primary-btn_unfilled" onClick={handleIPSkippingEditItemAddRange}>{t("button-add")}</button>
                                        </div>
                                        <div className="popup__footer">
                                            <div className="grid-wrapper">
                                                <button className="primary-btn primary-btn_unfilled" onClick={(e) => (setIsOpenIpskippingPopup(false))}>{t("button-cancel")}</button>
                                                <button className="primary-btn" disabled={ipSkippingEditItem?.items && ipSkippingEditItem?.items.length <= 0} onClick={handleIPSkippingListUpdate}>{t("button-save")}</button>
                                            </div>
                                        </div>
                                    </Popup>
                                </>
                                : ''
                        }
                    </>
            }
        </>
    );
};