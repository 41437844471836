import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import loginUser from "../api/users/loginUser";
import getData from "../api/users/getData";

export const authUser = createAsyncThunk('auth/authUser', async ({ email, password }, { rejectWithValue }) => {
    const response = await loginUser(email, password);
    const data = await response.json();

    if (response.ok) {
        localStorage.setItem('authToken', 'Bearer ' + data.token);
        localStorage.setItem('authUser', JSON.stringify(data.user));
        return data;
    } else {
        return rejectWithValue(data);
    }
});

export const fetchUserData = createAsyncThunk('auth/fetchUserData', async (token, { rejectWithValue, dispatch }) => {
    try {
        const response = await getData(token);
        if (!response || response?.message === 'Unauthorized') {
            dispatch(logout());
            return rejectWithValue(response); 
        }
        return response;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});


const initialState = {
    isAuthenticated: !!localStorage.getItem('authToken'),
    token: localStorage.getItem('authToken'),
    status: 'idle',
    error: null,
    data: localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.isAuthenticated = false;
            state.data = null;
            state.token = null;
            localStorage.removeItem('authToken');
            localStorage.removeItem('authUser');
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(authUser.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(authUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.token = 'Bearer ' + action.payload.token;
                state.isAuthenticated = true;
                state.data = action.payload.user;
                state.error = null;
            })
            .addCase(authUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Failed to authenticate';
            })
            .addCase(fetchUserData.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
                localStorage.setItem('authUser', JSON.stringify(action.payload));
                state.error = null;
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            });
    }
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
